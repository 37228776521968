import React from "react";
import contactImage from "../../img/background-white.jpg"; // Adjust the path based on where your image is located

export default function Contact() {
  return (
    <div>
      <img src={contactImage} alt="Contact" className="w-full h-auto" />
    </div>
  );
}
